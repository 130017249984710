.navbar {
	box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
		0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
	font-size: 0.9rem;
	font-weight: 600;
	margin-bottom: 20px;
}

.navbar-brand {
	font-size: 1rem;
}
.nav-link.active {
	font-weight: 600;
	font-style: italic;
}

.currency-table {
	font-size: 0.8rem;
}

.profileTable {
	border: 1px solid gainsboro;
}

.card {
	border-radius: 5px;
	margin: 5px;
}

.product-img {
	width: 100px;
	height: 100px;
}
